import { Form, Input, Modal, Spin, message } from "antd";
import React, { useState } from "react";
import SecondaryButton from "../../../UI/SecondaryButton/SecondaryButton";
import { postRequest } from "../../../../service/ApisFunctions";
import { useDispatch, useSelector } from "react-redux";
import { BookingPaymentCoupon } from "./BookingPaymentCoupon";
import { useNavigate } from "react-router-dom";
import {
  setBookingFlowCouponDetail,
  setBookingFlowMedialInfoSteps,
} from "../../../../Redux/Reducers/bookingSlice";
import CouponAppled from "../../../ApplyCoupon/couponAppled";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

export const BookingPaymentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [cvc, setCVC] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [couponDetails, setCouponDetails] = useState(null);

  const bookingData = useSelector((state) => state.booking);
  const completeUser = useSelector(
    (state) => state.generalReducer?.completeUser
  );
  const patientDetails = bookingData?.bookingFlowPatientData;

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const applyDiscount = (price, discountType, discountValue) => {
    if (discountType === "value") {
      return price - discountValue;
    } else {
      const discountAmount = (discountValue / 100) * price;
      return price - discountAmount.toFixed(2);
    }
  };
  const price = bookingData?.bookingFlowPrice;
  const finalPrice = bookingData?.bookingFlowCouponDetail?._id
    ? applyDiscount(
        price,
        bookingData?.bookingFlowCouponDetail?.discountType,
        bookingData?.bookingFlowCouponDetail?.discountValue
      )
    : "";

  const handleSubmitPayment = async () => {
    if (cardNumber.length !== 19) {
      message.warning("Please enter valid card number");
      return;
    } else if (cvc.length !== 3) {
      message.warning("Please enter valid card cvc");
      return;
    } else if (expiryDate.length !== 7) {
      message.warning("Please enter valid card expiry date");
      return;
    } else {
      if ((cardNumber, cvc, finalPrice || bookingData?.bookingFlowPrice)) {
        setLoading(true);
        const onSuccess = (res) => {
          setLoading(false);
          hangleCreateBooking();
        };
        const onError = (res) => {
          setLoading(false);
          message.error(res?.error_message);
        };
        const formData = {
          card_number: cardNumber.replace(/\s/g, ""),
          card_expiration: expiryDate.replace(/\s/g, "").replace("/", ""),
          card_code: cvc,
          amount: finalPrice?.toString() || bookingData?.bookingFlowPrice,
        };
        await postRequest(
          formData,
          "auth/authorized_card_payment",
          true,
          onSuccess,
          onError
        );
      } else {
        message.warning("Please enter valid card details");
      }
    }
  };
  const handleCardNumberChange = (value) => {
    const input = value.replace(/\D/g, "");
    let formattedInput = "";

    for (let i = 0; i < input.length; i += 4) {
      formattedInput += input.substr(i, 4) + " ";
    }

    setCardNumber(formattedInput.trim());
  };
  const handleExpiryDateChange = (value) => {
    const input = value.replace(/\D/g, "");
    let formattedInput = "";

    if (input.length >= 2) {
      const month = input.substr(0, 2);
      if (parseInt(month, 10) > 0 && parseInt(month, 10) <= 12) {
        formattedInput += month;

        const enteredYear = parseInt(input.substr(2, 2), 10);
        if (enteredYear >= 0 && enteredYear < 100) {
          formattedInput += " / " + input.substr(2, 2);
        } else if (input.length > 2) {
          formattedInput += " / " + input.substr(2, 4);
        }
      }
    } else {
      formattedInput += input;
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;

    const enteredYear =
      parseInt(input.substr(2, 2), 10) + currentYear - (currentYear % 100);
    const enteredMonth = parseInt(input.substr(0, 2), 10);

    setIsInvalidDate(
      isNaN(enteredYear) ||
        isNaN(enteredMonth) ||
        enteredYear < currentYear ||
        (enteredYear === currentYear && enteredMonth < currentMonth)
    );

    setIsExpired(
      enteredYear < currentYear ||
        (enteredYear === currentYear && enteredMonth < currentMonth)
    );
    setExpiryDate(formattedInput.trim());
  };

  const hangleCreateBooking = () => {
    const guardian = {
      first_name: patientDetails?.guardianFirstName || "",
      last_name: patientDetails?.guardianLastName || "",
      middle_name: patientDetails?.guardian_middle_name,
      email: patientDetails?.email || "",
      phone_number: patientDetails?.phone_number || "",
      location: bookingData?.bookingFlowLocation,
      guardian_id: patientDetails?.file || "",
      dob: patientDetails?.guardianDob || "",
    };
    const patient = {
      first_name: patientDetails?.first_name,
      last_name: patientDetails?.last_name,
      middle_name: patientDetails?.middle_name,
      prefix: patientDetails?.prefix,
      suffix: patientDetails?.suffix,
      email:
        bookingData?.bookingFlowAs !== "guardian" ? patientDetails?.email : "",

      phone_number: patientDetails?.phone_number,
      dob: patientDetails?.dob,
      ...(bookingData?.bookingFlowType === "Medical consultation" && {
        doseSpotDob: moment(patientDetails?.dob).format('YYYY-MM-DD')
      }),

      height: patientDetails?.height,
      weight: patientDetails?.weight,
      location: {
        address:
        bookingData?.bookingFlowLocation?.address1 + " " + bookingData?.bookingFlowLocation?.address2,
        address1: bookingData?.bookingFlowLocation?.address1,
        address2: bookingData?.bookingFlowLocation?.address2,
        coordinates: [0, 0],
      },
      language: patientDetails?.language,
      city: patientDetails?.city,
      zipCode: patientDetails?.zipCode,
      gender: patientDetails?.gender,
      birthGender: patientDetails?.birthGender,
      sexualOrientation: patientDetails?.sexualOrientation,
      race: patientDetails?.race,
      ethnicity: patientDetails?.ethnicity,
      pronoun: patientDetails?.pronoun,
      PrimaryPhoneType: patientDetails?.PrimaryPhoneType?.toString(),
      ...(patientDetails?.relation && { relation: patientDetails?.relation }),
    };

    if (bookingData?.bookingFlowType === "Medical consultation") {
      hangleSubmitVideoBooking(guardian, patient);
    } else {
      hangleSubmitProgramBooking(guardian, patient);
    }
  };

  const hangleSubmitProgramBooking = async (guardian, patient) => {
    setLoading(true);
    const onSuccess = (res) => {
      setLoading(false);
      setCouponDetails({});
      message.success("Payment successful, Please fill the questionnaire");
      dispatch(setBookingFlowMedialInfoSteps(0));
      dispatch(setBookingFlowCouponDetail(null));
      navigate(`/medical-info/${res?.data?._id}`);
    };
    const onError = (err) => {
      setLoading(false);
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
    };
    const dataToSend = {
      state: patientDetails?.state || "",
      isExisting:
        bookingData?.bookingFlowAs === "guardian" &&
        bookingData?.bookingFlowOtherPatient?._id
          ? true
          : false,
      booking_type: "program",
      booking_as: bookingData?.bookingFlowAs || "",
      past_medical_history: completeUser?.past_medical_history?.detail,
      past_social_history: completeUser?.past_social_history?.detail,
      updateMedicalHistory: completeUser?.past_medical_history?.detail
        ? true
        : false,
      updateSocialHistory: completeUser?.past_social_history?.detail
        ? true
        : false,
      isMedicalHistoryAttempted: completeUser?.past_medical_history?.detail
        ? true
        : false,
      isSocialHistoryAttempted: completeUser?.past_social_history?.detail
        ? true
        : false,
      patientId:
        bookingData?.bookingFlowAs === "guardian" &&
        bookingData?.bookingFlowOtherPatient?._id
          ? bookingData?.bookingFlowOtherPatient?._id
          : "",
      patient: JSON.stringify(patient),
      guardian:
        bookingData?.bookingFlowAs === "guardian"
          ? JSON.stringify(guardian)
          : null,

      timeslot_id: bookingData?.bookingFlowIsUrgent
        ? ""
        : bookingData?.bookingFlowSchedule?._id,
      provider_id: bookingData?.bookingFlowIsUrgent
        ? ""
        : bookingData?.bookingFlowDoctor?._id,
      program: bookingData?.bookableFlowProgram?._id || "",
      payment: bookingData?.bookingFlowPrice?.toString(),
      payment_status: true,
      couponId: couponDetails?._id || "",
      pharmacy: completeUser?.pharmacy,
      booking_access_type: "video",
    };
    await postRequest(
      dataToSend,
      "booking/add_program_booking",
      true,
      onSuccess,
      onError
    );
  };
  const hangleSubmitVideoBooking = async (guardian, patient) => {
    setLoading(true);

    const onSuccess = (res) => {
      setLoading(false);
      setCouponDetails({});
      message.success("Payment successful, Please fill the questionnaire");
      dispatch(setBookingFlowMedialInfoSteps(0));
      dispatch(setBookingFlowCouponDetail(null));
      navigate(`/medical-info/${res?.data?._id}`);
    };
    const onError = (err) => {
      setLoading(false);
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
    };
    const dataToSend = {
      state: patientDetails?.state || "",
      isExisting:
        bookingData?.bookingFlowAs === "guardian" &&
        bookingData?.bookingFlowOtherPatient?._id
          ? true
          : false,
      booking_type: "video",
      booking_as: bookingData?.bookingFlowAs || "",
      patientId:
        bookingData?.bookingFlowAs === "guardian" &&
        bookingData?.bookingFlowOtherPatient?._id
          ? bookingData?.bookingFlowOtherPatient?._id
          : "",
      patient: JSON.stringify(patient),
      guardian:
        bookingData?.bookingFlowAs === "guardian"
          ? JSON.stringify(guardian)
          : null,
      past_medical_history: completeUser?.past_medical_history?.detail,
      past_social_history: completeUser?.past_social_history?.detail,
      updateMedicalHistory: completeUser?.past_medical_history?.detail
        ? true
        : false,
      updateSocialHistory: completeUser?.past_social_history?.detail
        ? true
        : false,
      isMedicalHistoryAttempted: completeUser?.past_medical_history?.detail
        ? true
        : false,
      isSocialHistoryAttempted: completeUser?.past_social_history?.detail
        ? true
        : false,
      timeslot_id: bookingData?.bookingFlowIsUrgent
        ? ""
        : bookingData?.bookingFlowSchedule?._id,
      provider_id: bookingData?.bookingFlowIsUrgent
        ? ""
        : bookingData?.bookingFlowDoctor?._id,
      category: bookingData?.bookableFlowCondition?._id || "",
      speciality: bookingData?.bookableFlowSpeciality?._id || "",
      payment: bookingData?.bookingFlowPrice?.toString(),
      payment_status: true,
      couponId: couponDetails?._id || "",
      pharmacy: completeUser?.pharmacy,

      booking_access_type: bookingData?.bookingFlowIsUrgent
        ? "message"
        : bookingData?.bookingFlowAccessType,
    };
    await postRequest(
      dataToSend,
      "booking/add_video_booking",
      true,
      onSuccess,
      onError
    );
  };

  return (
    <Spin spinning={loading}>
      <Form
        style={{ width: mobileResponsive ? "100%" : "60%", margin: "auto" }}
        layout="vertical"
        onFinish={handleSubmitPayment}
      >
        <div className="payment">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>Pay With Card</h3>
          </div>
          <Form.Item
            style={{ marginTop: "10px" }}
            label="Card No"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Card no"
              maxLength={"19"}
              onChange={(e) => handleCardNumberChange(e.target.value)}
              className="ant-input-affix-wrapper"
              value={cardNumber}
            />
          </Form.Item>
          <Form.Item label="CVC" rules={[{ required: true }]}>
            <Input
              placeholder="CVC"
              type="number"
              value={cvc}
              onChange={(e) => {
                setCVC(e.target.value.length === 4 ? cvc : e.target.value);
              }}
              className="ant-input-affix-wrapper"
            />
          </Form.Item>
          <Form.Item
            label="Expiry Date ( MM/YY )"
            rules={[
              { required: true, message: "Please enter a valid expiry date" },
            ]}
            validateStatus={isInvalidDate ? "error" : ""}
            help={isInvalidDate && "Please enter a valid expiry date"}
          >
            <Input
              placeholder="Expiry Date MM/YY"
              type="text"
              onChange={(e) => handleExpiryDateChange(e.target.value)}
              value={expiryDate}
              className={
                isExpired
                  ? "ant-input-affix-wrapper expired-color"
                  : "ant-input-affix-wrapper"
              }
            />
          </Form.Item>

          {bookingData?.bookingFlowCouponDetail?._id ? (
            <CouponAppled
              bookingPrice={bookingData?.bookingFlowPrice}
              finalPrice={finalPrice}
            />
          ) : (
            <BookingPaymentCoupon
              bookingType={bookingData?.bookingFlowType}
              setLoading={setLoading}
              setCouponDetails={setCouponDetails}
              couponDetails={couponDetails}
              category={bookingData?.bookableFlowCondition?._id}
              speciality={bookingData?.bookableFlowSpeciality?._id}
              program={bookingData?.bookableFlowProgram?._id}
            />
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <SecondaryButton className={"pay-btn"}>
              Confirm payment ${finalPrice || bookingData?.bookingFlowPrice}
            </SecondaryButton>
          </div>
        </div>
      </Form>

      {/* <Modal
        centered
        open={!completeUser?.isFinancialResponsibilityConsentAllowed && true}
        closable={false}
        width={mobileResponsive ? "90%" : "70%"}
        footer={false}
      >
        <SingleConsentForm
          consent={consentForms["financialResponsibility"]}
          type="financialResponsibility"
        />
      </Modal> */}
    </Spin>
  );
};
