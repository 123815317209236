import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts/Layout/Layouts";
import classes from "../../../Pages/Consultation/Consultations.module.scss";
import SecondaryButton from "../../UI/SecondaryButton/SecondaryButton";
import { patientHome } from "../../../assets";
import { Button, Col, Empty, Row, Spin, message } from "antd";
import { useMediaQuery } from "react-responsive";
import { getRequest } from "../../../service/ApisFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompleteUser } from "../../../Redux/Reducers/gernalSlice";
import {
  setBookableFlowCondition,
  setBookingFlowInformationSteps,
  setBookingFlowIsUrgent,
} from "../../../Redux/Reducers/bookingSlice";
import {
  setIsConsentForm,
  setIsProfileFilled,
} from "../../../Redux/Reducers/authSlice";
import OrangeButton from "../../UI/OrangeButton/OrangeButton";
import {
  setRefillCondition,
  setRefillPrescriptions,
  setRefillSpeciality,
  setServiceMedications,
} from "../../../Redux/Reducers/refillsSlice";
import { CheckEmptyContent } from "../../../Utils/helper";

const SpecialityDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 1000px)",
  });
  const token = useSelector((state) => state.authReducer.paitentToken);
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const [loading, setLoading] = useState(false);
  const [specialityDetail, setspecialityDetail] = useState(null);
  const getSpecialityById = async () => {
    setLoading(true);
    const onSuccess = (res) => {
      setspecialityDetail(res?.data);

      setLoading(false);
    };
    const onError = (err) => {
      setLoading(false);
      message.error(err);
    };

    await getRequest(
      "",
      `${"speciality/detail_specialty"}/${id}`,
      true,
      onSuccess,
      onError
    );
  };
  const getStartedBtn = (urgent) => {
    if (urgent) {
      dispatch(setBookingFlowIsUrgent(true));
    } else {
      dispatch(setBookingFlowIsUrgent(false));
    }
    if (!token) {
      dispatch(setCompleteUser({}));
      dispatch(setBookingFlowInformationSteps(0));
      navigate("/consultation-booking");
      return;
    } else if (completeUser?._id && !completeUser?.isProfileFilled) {
      dispatch(setIsProfileFilled({ personalInfo: true }));
    } else if (completeUser?._id && !completeUser?.allowConsent) {
      dispatch(setIsConsentForm(true));
    } else {
      dispatch(setBookingFlowInformationSteps(0));
      navigate("/consultation-booking");
    }
  };
  const handleRefillRequest = () => {
    dispatch(setRefillSpeciality(specialityDetail?._id));
    dispatch(setRefillCondition(""));
    dispatch(setRefillPrescriptions([]));
    dispatch(setServiceMedications([]));

    navigate("/refill-request?isSpecialitySelected=true");
  };
  useEffect(() => {
    getSpecialityById();
  }, [id]);

  return (
    <Layouts>
      <Spin spinning={loading}>
        <div className={classes.consult}>
          <div className={classes.head}>
            <div>
              <h3>{specialityDetail?.speciality_name || "-"}</h3>
              <div className={classes.bodyCont}>
                    <p>{specialityDetail?.description || "--"}</p>
                  </div>
              <SecondaryButton
                className={classes.button}
                children="Schedule a video or phone call visit "
                onClick={() => getStartedBtn()}
              />
              {specialityDetail?.speciality_name?.replace(/\s/g, "") ===
              "Mental Health"?.replace(/\s/g, "") ? null : (
                <>
                  <OrangeButton
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                    className={classes.button}
                    children="Messaging visit"
                    onClick={() => getStartedBtn(true)}
                  />
                  {/* <PrimaryButton
                    className={classes.button}
                    children="Get Refill"
                    onClick={() => handleRefillRequest()}
                  /> */}
                </>
              )}
            </div>
            <div>
              <img
                style={{ borderRadius: "7px  " }}
                src={specialityDetail?.image || patientHome}
                alt="$$$"
              />
            </div>
          </div>
          <div className={classes.body}>
            <Row style={{ width: "100%" }} gutter={[15, 15]}>
              {/* {CheckEmptyContent(specialityDetail?.how_it_work) && (
                <Col span={mobileResponsive ? 24 : 24}>
                  <div className={classes.bodyCont}>
                    <h5>How It Works</h5>
                    <div
                      className={classes.customHtmlRenderingDiv}
                      dangerouslySetInnerHTML={{
                        __html: specialityDetail?.how_it_work || "--",
                      }}
                    />
                  </div>
                </Col>
              )} */}

              {specialityDetail?.overview && (
                <Col span={mobileResponsive ? 24 : 24}>
                  <div className={classes.bodyCont}>
                    <h5>Overview</h5>
                    <p>{specialityDetail?.overview || "--"}</p>
                  </div>
                </Col>
              )}

              {/* {CheckEmptyContent(specialityDetail?.common_symptoms) && (
                <Col span={mobileResponsive ? 24 : 12}>
                  <div className={classes.bodyCont}>
                    <h5>Common Symptoms</h5>
                    <div
                      className={classes.customHtmlRenderingDiv}
                      dangerouslySetInnerHTML={{
                        __html: specialityDetail?.common_symptoms,
                      }}
                    />
                  </div>
                </Col>
              )}

              {CheckEmptyContent(specialityDetail?.treatment) && (
                <Col span={mobileResponsive ? 24 : 12}>
                  <div className={classes.bodyCont}>
                    <h5>OhanaDoc Treatment Options</h5>
                    <div
                      className={classes.customHtmlRenderingDiv}
                      dangerouslySetInnerHTML={{
                        __html: specialityDetail?.treatment,
                      }}
                    />
                  </div>
                </Col>
              )}
              {(specialityDetail?.medicationsOffered?.medicationNames?.length >
                0 ||
                specialityDetail?.medicationsOffered?.note) && (
                <Col span={mobileResponsive ? 24 : 12}>
                  <div className={classes.bodyCont}>
                    <h5>Medications Offered at OhanaDoc</h5>
                    {specialityDetail?.medicationsOffered?.medicationNames
                      ?.length > 0 ? (
                      specialityDetail?.medicationsOffered?.medicationNames?.map(
                        (item, index) => (
                          <p key={index}>
                            {index + 1 + ". "}
                            {item?.medicationName || "--"}
                          </p>
                        )
                      )
                    ) : (
                      <Empty />
                    )}

                    <h5 style={{ marginTop: "20px" }}>Note</h5>

                    <p>{specialityDetail?.medicationsOffered?.note || "--"}</p>
                  </div>
                </Col>
              )}
              {(specialityDetail?.speciality_name ||
                specialityDetail?.category_name) && (
                <Col span={12}>
                  <div className={classes.bodyCont}>
                    <h5>
                      Why Choose OhanaDoc for
                      {specialityDetail?.speciality_name ||
                        specialityDetail?.category_name}
                      ?
                    </h5>
                    <p>{specialityDetail?.why || "--"}</p>

                    <h5 style={{ marginTop: "20px" }}>Remember</h5>
                    <p>{specialityDetail?.remember || "--"}</p>
                  </div>
                </Col>
              )}
              {specialityDetail?.categories?.length > 0 && (
                <Col span={12}>
                  <div className={classes.bodyCont}>
                    <h5 style={{ marginBottom: "10px" }}>Conditions</h5>
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                    >
                      {specialityDetail?.categories?.map((elem) => (
                        <Button
                          onClick={() => {
                            dispatch(setBookableFlowCondition(elem));
                            navigate("/booking-details");
                          }}
                        >
                          {elem?.category_name}
                        </Button>
                      ))}
                    </div>
                  </div>
                </Col>
              )}  */}
            </Row>
          </div>

          {/* <div className={classes?.getStarted}>
            <SecondaryButton
              className={classes.button}
              children="Schedule a video or phone call visit"
              onClick={() => getStartedBtn()}
            />
          </div> */}
        </div>
      </Spin>
    </Layouts>
  );
};

export default SpecialityDetails;
