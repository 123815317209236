import { patientHome } from "../../assets";
import classes from "./Consultations.module.scss";
import SecondaryButton from "../../Components/UI/SecondaryButton/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompleteUser,
  setInfoSteps,
} from "../../Redux/Reducers/gernalSlice";
import {
  setBookingFlowInformationSteps,
  setBookingFlowIsUrgent,
} from "../../Redux/Reducers/bookingSlice";
import Layouts from "../../Components/Layouts/Layout/Layouts";
import {
  setIsConsentForm,
  setIsProfileFilled,
} from "../../Redux/Reducers/authSlice";
import { useEffect } from "react";
import { CheckEmptyContent } from "../../Utils/helper";

const TreatmentConsultations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const speacilityDetails = useSelector(
    (state) => state.authReducer.speacilityDetails
  );

  const speacilityImage = useSelector(
    (state) => state.authReducer.speacilityImage
  );

  const token = useSelector((state) => state.authReducer.paitentToken);

  const getStartedBtn = () => {
    if (!token) {
      dispatch(setCompleteUser({}));
      dispatch(setBookingFlowInformationSteps(0));
      navigate("/consultation-booking");
    } else if (completeUser?._id && !completeUser?.isProfileFilled) {
      dispatch(setIsProfileFilled({ personalInfo: true }));
    } else if (completeUser?._id && !completeUser?.allowConsent) {
      dispatch(setIsConsentForm(true));
    } else {
      dispatch(setInfoSteps({}));
      dispatch(setBookingFlowInformationSteps(0));
      navigate("/consultation-booking");
    }
  };

  useEffect(() => {
    dispatch(setBookingFlowIsUrgent(false));
  });

  return (
    <Layouts>
      <div className={classes.consult}>
        <div className={classes.head}>
          <div>
            <h3>{speacilityDetails?.treatment_name || "-"}</h3>
            <div className={classes.bodyContent}>
              <p>{speacilityDetails?.description?.[0]?.detail || "--"}</p>
            </div>
            <SecondaryButton
              className={classes.button}
              children="Get Started"
              onClick={() => getStartedBtn()}
            />
          </div>
          <div>
            <img
              style={{ borderRadius: "7px" }}
              src={speacilityImage || patientHome}
            />
          </div>
        </div>
        <div className={classes.treatmentBody}>
          {/* {CheckEmptyContent(speacilityDetails?.how_it_work) && (
            <div className={classes.bodyContent}>
              <h5>How It Works</h5>
              <div
                className={classes.customHtmlRenderingDiv}
                dangerouslySetInnerHTML={{
                  __html: speacilityDetails?.how_it_work,
                }}
              />
            </div>
          )} */}
          {speacilityDetails?.overview && (
            <div className={classes.bodyContent}>
              <h5>Overview</h5>
              <p>{speacilityDetails?.overview || "--"}</p>
            </div>
          )}
          {/* {speacilityDetails?.whyUs && (
            <div className={classes.bodyContent}>
              <h5>Choosing a reputable weight management service</h5>
              <p>{speacilityDetails?.whyUs || "--"}</p>
            </div>
          )}
          {speacilityDetails?.description?.length > 0 && (
            <div className={classes.bodyContent}>
              <h5>Healthy lifestyle to follow</h5>
              {speacilityDetails?.description?.map((item, index) => (
                <p key={index} className={classes.subdes}>
                  <b className={classes.subheading}>{item?.title} : </b>
                  {item?.detail}
                </p>
              ))}
            </div>
          )} */}
        </div>
        {/* <div className={classes?.getStarted}>
          <SecondaryButton
            className={classes.button}
            children="Get Started"
            onClick={() => getStartedBtn()}
          />
        </div> */}
      </div>
    </Layouts>
  );
};

export default TreatmentConsultations;
