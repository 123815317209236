import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

// Function to get the current location and fetch the exact address from Mapbox Geocoding API
export async function getCurrentLocationAndFetchAddress() {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Replace 'YOUR_MAPBOX_ACCESS_TOKEN' with your actual Mapbox access token
          const access_token =
            'pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ';
          const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${access_token}`;

          try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.features.length > 0) {
              const location = data.features[0].place_name;
              resolve(location);
            } else {
              reject('Location not found.');
            }
          } catch (error) {
            reject('Error fetching location data');
          }
        },
        error => {
          console.log(error);
          reject('Error getting geolocation');
        }
      );
    } else {
      reject('Geolocation is not available in this browser.');
    }
  });
}


export const DateTimeLocalConverter = string => {
  const date = new Date(string);
  const formattedDateTime = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}T${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
  return formattedDateTime;
};

export const disabledFutureDate = current => {
  // Disable dates that are after today
  return current && current > moment().endOf('day');
};

const MobileResponsiveComponent = () => {
  const isMobileView = useMediaQuery({ query: '(max-width: 800px)' });

  return isMobileView;
};

export const mobileResponsive = () => {
  return MobileResponsiveComponent();
};

// Strong Password

export const strongPasswordValidator = (rule, value, callback) => {
  // Password must be at least 8 characters long
  if (value && value.length < 8) {
    callback('Password must be at least 8 characters long');
  }

  // Password must contain at least one uppercase letter
  else if (!/[A-Z]/.test(value)) {
    callback('Password must contain at least one uppercase letter');
  }

  // Password must contain at least one lowercase letter
  else if (!/[a-z]/.test(value)) {
    callback('Password must contain at least one lowercase letter');
  }

  // Password must contain at least one digit
  else if (!/\d/.test(value)) {
    callback('Password must contain at least one digit');
  }

  // Password must contain at least one special character
  else if (!/[\!@#$%^&*()_+\-=\[\]{};'`:"\\|,.<>\/?]/.test(value)) {
    callback('Password must contain at least one special character');
  } else {
    callback();
  }
};

export const validatePhoneNumber = (rule, value, callback) => {
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  if (!value) {
    callback('Please enter phone number');
  } else if (!phoneRegex.test(value)) {
    callback('Phone number must be formatted as (xxx) xxx-xxxx');
  } else if (value.match(/(.)\1{6,}/)) {
    callback('Phone number must not contain 7 or more repeated numbers');
  } else if (!isValidAreaCode(value)) {
    callback('Invalid area code');
  } else {
    callback();
  }
};

// Function to check if the area code is valid
const isValidAreaCode = phoneNumber => {
  const areaCode = phoneNumber.substring(1, 4); // Extract area code from (xxx) xxx-xxxx format
  const invalidAreaCodes = ['555']; // Invalid area codes
  return (
    !invalidAreaCodes.includes(areaCode) &&
    !areaCode.startsWith('0') &&
    !areaCode.startsWith('1')
  );
};

export const formatPhoneNumber = input => {
  // Remove all non-digit characters
  const cleaned = input.replace(/\D/g, '');

  // Apply formatting (assuming US format)
  let formatted = '';
  if (cleaned.length >= 1) {
    formatted = `(${cleaned.slice(0, 3)}`;
  }
  if (cleaned.length > 3) {
    formatted += `) ${cleaned.slice(3, 6)}`;
  }
  if (cleaned.length > 6) {
    formatted += `-${cleaned.slice(6, 10)}`;
  }
  return formatted;
};

export const formatHeight = input => {
  const cleaned = input.replace(/\D/g, '');
  let formatted = '';
  if (input?.length >= 4) {
    return input.slice(0, 4);
  }
  if (cleaned.length === 1) {
    formatted = `${cleaned}`;
  } else if (cleaned.length > 1) {
    formatted = `${cleaned[0]}'${cleaned.slice(1)}`;
  }
  return formatted;
};

export const validateHeight = profileHeightUnit => (rule, value) => {
  if (value && value < 1) {
    return Promise.reject(new Error('Height must be at least 1'));
  }
  const decimalPart = value?.toString()?.split("'")[1];
  if (decimalPart && decimalPart?.length > 2) {
    return Promise.reject(
      new Error('Height must have at most 2 decimal places.')
    );
  }
  if (profileHeightUnit === 1 && value > 99) {
    return Promise.reject(new Error('Height in inches must be 99 or less.'));
  } else if (profileHeightUnit === 2 && value > 999) {
    return Promise.reject(new Error('Height in cm must be 999 or less.'));
  } else if (profileHeightUnit === 3 && value > 9) {
    return Promise.reject(new Error('Height in ft must be 9 or less.'));
  } else if (profileHeightUnit === 3 && decimalPart > 12) {
    return Promise.reject(new Error('Enter a valid height.'));
  }
  return Promise.resolve();
};

export const validateWeight = profileWeightUnit => (rule, value) => {
  if (value && value < 1) {
    return Promise.reject(new Error('Weight must be at least 1'));
  }
  const decimalPart = value?.toString()?.split('.')[1];

  if (decimalPart && decimalPart?.length > 2) {
    return Promise.reject(
      new Error('Weight must have at most 2 decimal places')
    );
  }
  if (profileWeightUnit === 1 && value > 9999) {
    return Promise.reject(new Error('Weight in lbs must be 9999 or less'));
  }
  if (profileWeightUnit === 2 && value > 999) {
    // Assuming a maximum of 12 inches if the unit is inches.
    return Promise.reject(new Error('Weight in kgs must be 999 or less'));
  }
  return Promise.resolve();
};

// Return Unit

export const getHeightUnit = value => {
  if (value == 1) {
    return 'In';
  }
  if (value == 2) {
    return 'Cm';
  }
  if (value == 3) {
    return 'Ft';
  }
};

export const getWeightUnit = value => {
  if (value == 1) {
    return 'Lb';
  }
  if (value == 2) {
    return 'Kg';
  }
};

export const CapitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ValidateMaxLength = (maxLength, type) => ({
  validator(_, value) {
    if (value && value.length > maxLength) {
      return Promise.reject(`${type} must be at most ${maxLength} characters`);
    }
    return Promise.resolve();
  }
});

export const ValidateMaxLengthAndContent = (maxLength, type) => ({
  validator(_, value) {
    if (value) {
      // Check for max length
      if (value.length > maxLength) {
        return Promise.reject(
          `${type} must be at most ${maxLength} characters`
        );
      }

      // Check for special characters and numbers
      const specialCharAndNumberPattern = /[^a-zA-Z\s]/;
      if (specialCharAndNumberPattern.test(value)) {
        return Promise.reject(
          `${type} must not contain special characters or numbers`
        );
      }
    }
    return Promise.resolve();
  }
});

export const FormatFullName = (
  prefix,
  firstName,
  middleName = '',
  lastName,
  suffix = ''
) => {
  // Create an array with non-empty parts
  const nameParts = [prefix, firstName, middleName, lastName, suffix]?.filter(
    part => part?.trim() !== ''
  );

  // Join the parts with a space
  return nameParts.join(' ');
};


export const CheckEmptyContent=(htmlContent)=> {
  // Create a temporary div element to parse the HTML content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  // Check if the text content of the div is empty or contains only whitespace
  const hasContent = tempDiv.textContent.trim().length > 0;
  
  // Return the original content or a fallback if it's empty
  return hasContent ? htmlContent : '';
}