import { Col, DatePicker, Form, Input, Row, Select, message } from "antd";
import React, { useEffect } from "react";
import {
  disabledFutureDate,
  ValidateMaxLength,
  ValidateMaxLengthAndContent,
} from "../../../../Utils/helper";
import BlueButton from "../../../../Components/UI/BlueButton/BlueButton";
import { gendersList } from "../../../../Utils/constant";
import BackButton from "../../../../Components/UI/BackButton/BackButton";
import dayjs from "dayjs";
import CustomDesignedDatePicker from "../../../../Components/UI/CustomDesignedDatePicker/CustomDesignedDatePicker";
import moment from "moment";
const SecondStep = ({ setSignUpSteps, setSignUpData, signUpData }) => {
  const [form] = Form.useForm();
  
  const handleSubmit = async (e) => {
    const currentDate = new Date();
    const dob = new Date(e?.dob?.$d); // Assuming dob is in a valid date format
    
    // Calculate the difference in years
    let age = currentDate.getFullYear() - dob.getFullYear();
    
    // Check if the birthday has occurred this year
    const isBirthdayPassed =
      currentDate.getMonth() > dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() >= dob.getDate());
  
    // Adjust age if birthday hasn't passed this year
    if (!isBirthdayPassed) {
      age -= 1;
    }
  
    if (age >= 18) {
      const createPatient = {
        first_name: e.firstName,
        last_name: e?.lastName,
        middle_name: e?.middle_name || "",
        gender: e?.gender,
        dob: e?.dob,
        suffix: e?.suffix || "",
        prefix: e?.prefix || "",
        doseSpotDob : String(moment(e?.dob?.$d).format('YYYY-MM-DD'))
      };
      setSignUpData((prev) => ({ ...prev, ...createPatient }));
      setSignUpSteps(2);
    } else {
      message.warning(
        "Your age must be 18+ to join Ohana Doc. If you're younger, your parents can sign up instead."
      );
    }
  };
  

  useEffect(() => {
    form.setFieldsValue({
      firstName: signUpData?.first_name || null,
      lastName: signUpData?.last_name || null,
      prefix: signUpData?.prefix || null,
      middle_name: signUpData?.middle_name || null,
      suffix: signUpData?.suffix || null,
      gender: signUpData?.gender || null,
      dob: signUpData?.dob ? dayjs(signUpData?.dob) : null,
    });
  }, []);

  return (
    <Form onFinish={handleSubmit} form={form} layout="vertical">
      <Row gutter={20}>
        <Col span={4}>
          <Form.Item
            name="prefix"
            label="Prefix"
            rules={[{ required: false }, ValidateMaxLength(10, "Prefix")]}
          >
            <Input
              className="ant-input-affix-wrapper"
              placeholder="Enter your prefix"
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { required: true, message: "Please enter first name" },
              ValidateMaxLengthAndContent(35, "First Name"),
            ]}
          >
            <Input
              className="ant-input-affix-wrapper"
              placeholder="Enter your First Name"
            />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item
            name="middle_name"
            label="Middle Name"
            rules={[
              { required: false },
              ValidateMaxLengthAndContent(35, "Middle Name"),
            ]}
          >
            <Input
              className="ant-input-affix-wrapper"
              placeholder="Enter your middle Name"
            />
          </Form.Item>
        </Col>

        <Col span={20}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              { required: true, message: "Please enter last name" },
              ValidateMaxLengthAndContent(35, "Last name"),
            ]}
          >
            <Input
              className="ant-input-affix-wrapper"
              placeholder="Enter your Last Name"
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="suffix"
            label="Suffix"
            rules={[{ required: false }, ValidateMaxLength(10, "Suffix")]}
          >
            <Input
              className="ant-input-affix-wrapper"
              placeholder="Enter your suffix"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="gender"
            label="Current Gender"
            rules={[
              {
                required: true,
                message: "Please select gender",
              },
            ]}
          >
            <Select className="ant-select-selector" placeholder="Select gender">
              {gendersList?.map((item, index) => (
                <Select.Option key={index} value={item?.name}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={24}>
          <Form.Item
            name='dob'
            label='Date Of Birth'
            rules={[
              {
                required: true,
                message: 'Please enter dob'
              }
            ]}
          >
            <DatePicker
              className='ant-input-affix-wrapper'
              disabledDate={disabledFutureDate}
            />
          </Form.Item>
        </Col> */}

        <Col span={24}>
          <Form.Item
            name="dob"
            label="Date Of Birth"
            rules={[
              {
                required: true,
                message: "Please enter dob",
              },
            ]}
          >
            <CustomDesignedDatePicker
              field={"dob"}
              form={form}
              defaultValue={signUpData?.dob}
            />
          </Form.Item>
        </Col>

        <Col style={{ marginTop: "20px" }} span={6}>
          <BackButton
            onClick={() => setSignUpSteps(0)}
            type="button"
            width="100%"
          >
            Back
          </BackButton>
        </Col>
        <Col style={{ marginTop: "20px" }} span={18}>
          <BlueButton type="submit" width="100%">
            Next
          </BlueButton>
        </Col>
      </Row>
    </Form>
  );
};

export default SecondStep;
