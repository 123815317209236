import React, { useEffect, useRef, useState } from "react";
import "./consultationVideoCall.scss";
import {
  CallCut,
  CameraIcon,
  MaxIcon,
  MicroPhoneIcon,
  TimerIcon,
  VideoCallIcon,
} from "../../assets";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";
import ConsultationParticipant from "./consultationParticipant";
import moment from "moment";
import {
  setAudioGlobal,
  setFullScreen,
  setVideoGlobal,
} from "../../Redux/Reducers/gernalSlice";
import Review from "../../Pages/Review/Review";
import { Modal } from "antd";

const ConsultationVideoCall = ({
  newVideoToken,
  booking,
  completeUser,
  isRestricted,
}) => {
  const [allowCamera, setAllowcamera] = useState(false);
  const [isCameraOn, setCameraOn] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLaunchVideo, setNewLaunchVlaunchVideo] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [room, setRoom] = useState(null);
  const videoRefRemote = useRef();
  const audioRefRemote = useRef();
  const videoRefLocal = useRef();
  const audioRefLocal = useRef();
  const dispatch = useDispatch();

  let tracks = [];

  const isAudioGlobal = useSelector(
    (state) => state?.generalReducer?.isAudioGlobal
  );
  const isVideoGlobal = useSelector(
    (state) => state?.generalReducer?.isVideoGlobal
  );

  const fullScreen = useSelector((state) => state?.generalReducer?.fullScreen);

  const singleParticipant = participants[participants.length - 1];
  const remoteParticipants =
    participants.length == 0 && !singleParticipant ? (
      <div
        style={{
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        className="title"
      >
        Waiting for Doctor to join consultation.
      </div>
    ) : (
      <ConsultationParticipant
        isAudio={singleParticipant?.audioTracks?.size > 0}
        isVideo={singleParticipant?.videoTracks?.size > 0}
        key={singleParticipant?.sid}
        participant={singleParticipant}
        videoRef={videoRefRemote}
        audioRef={audioRefRemote}
        booking={booking}
      />
    );

  useEffect(() => {
    const fetchAudioDevices = async () => {
      const audioDevices = await navigator.mediaDevices.enumerateDevices();
      const microphones = audioDevices.filter(
        (device) => device.kind === "audioinput"
      );
      // // setMicrophoneDevices(microphones);
      // const defaultMicrophoneId =
      //   microphones.length > 0 ? microphones[0].deviceId : "";
      // // setSelectedMicrophone(defaultMicrophoneId);
    };

    fetchAudioDevices();
    if (newLaunchVideo) {
      tracks = [];
      const connectVideo = async () => {
        if (newVideoToken) {
          const participantConnected = (participant) => {
            setParticipants((prevParticipants) => [
              ...prevParticipants,
              participant,
            ]);
          };
          const participantDisconnected = (participant) => {
            setParticipants((prevParticipants) =>
              prevParticipants.filter((p) => p !== participant)
            );
          };

          try {
            const audioTrack = await createLocalAudioTrack();
            tracks.push(audioTrack);
          } catch (error) {
            console.log("Failed Media:", error.name, error.message);
          }

          try {
            const videoTrack = await createLocalVideoTrack();
            tracks.push(videoTrack);
          } catch (error) {
            console.log("Failed Media:", error.name, error.message);
          }

          if (completeUser?.user_id && newVideoToken) {
            try {
              const result = await Video.connect(newVideoToken, {
                name: completeUser?.user_id?._id,
              });
              setRoom(result);
              result.on("participantConnected", participantConnected);
              result.on("participantDisconnected", participantDisconnected);

              result.participants.forEach(participantConnected);
            } catch (e) {
              console.log(e, "elrr");
            }
          }
          return () => {
            setRoom((currentRoom) => {
              if (
                currentRoom &&
                currentRoom.localParticipant.state === "connected"
              ) {
                currentRoom.localParticipant.tracks.forEach(function (
                  trackPublication
                ) {
                  trackPublication.track.stop();
                });
                currentRoom.disconnect();
                return null;
              } else {
                return currentRoom;
              }
            });
          };
        }
      };
      connectVideo();
    }
  }, [newVideoToken, newLaunchVideo]);

  const videoConstraints = {
    facingMode: isCameraOn ? "user" : "environment", // 'user' for front camera, 'environment' for back camera
  };

  useEffect(() => {
    if (
      booking?.booking_access_type === "audio" ||
      booking?.booking_access_type === "message"
    ) {
      dispatch(setVideoGlobal(false));
    } else if (booking?.booking_access_type === "video") {
      dispatch(setVideoGlobal(true));
    }
    const calculateTimeRemaining = () => {
      const now = moment();
      const scheduledTime = moment(booking?.schedule?.start_time);
      const duration = moment.duration(scheduledTime.diff(now));

      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes(); // Add this line

      if (days > 0) {
        setTimeRemaining(
          `${days} day${days > 1 ? "s" : ""} ${hours} hr${
            hours > 1 ? "s" : ""
          } ${minutes} min${minutes > 1 ? "s" : ""}`
        );
      } else if (hours > 0) {
        setTimeRemaining(
          `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min${
            minutes > 1 ? "s" : ""
          }`
        );
      } else {
        setTimeRemaining(`hr 00 : ${minutes} min${minutes > 1 ? "s" : ""}`);
      }
    };

    const timer = setInterval(calculateTimeRemaining, 1000);

    // Initial calculation
    calculateTimeRemaining();

    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, [booking]);

  const allowStaticCamera = () => {
    if (new Date(booking?.schedule?.end_time) <= new Date()) {
      setAllowcamera(false);
    } else {
      setAllowcamera(!allowCamera);
    }
  };

  const launchVideoCall = () => {
    if (booking?.urgent_care) {
      setNewLaunchVlaunchVideo(true);
    } else if (
      new Date() >=
        new Date(booking?.schedule?.start_time).setMinutes(
          new Date(booking?.schedule?.start_time).getMinutes() - 5
        ) &&
      new Date() <= new Date(booking?.schedule?.end_time)
    ) {
      setNewLaunchVlaunchVideo(true);
    }
  };
  const callCut = () => {
    if (room) {
      room?.localParticipant?.tracks?.forEach((publication) => {
        publication?.track?.stop();
        const attachedElements = publication?.track?.detach();
        attachedElements?.forEach((element) => element?.remove());
      });
      room.disconnect();
    }
    window.location.reload();
  };

  useEffect(() => {
    const checkScheduleAndRestrictions = () => {
      const currentTime = new Date();
      const endTime = new Date(booking?.schedule?.end_time);
      
      // Compare up to minutes or seconds
      const isSameTime = Math.abs(endTime - currentTime) <= 1000; // 1 second difference
  
      if (isSameTime || isRestricted?.restricted) {
        window.location.reload();
      }
    };
  
    // Check immediately
    checkScheduleAndRestrictions();
  
    // Optionally, you can keep checking every second (or other interval)
    const intervalId = setInterval(checkScheduleAndRestrictions, 1000); // Check every second
  
    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [booking, isRestricted]);
  
  return (
    <>
      <div style={fullScreen ? { marginTop: "0px" } : {}} className="videoCall">
        {!fullScreen && (
          <div className="videoHeader">
            <h2>Call Preview</h2>
            {/* <p>Troubleshoot?</p>. */}
          </div>
        )}
        <div
          style={fullScreen ? { borderRadius: "0px", height: "100vh" } : {}}
          className={
            new Date(booking?.schedule?.end_time) <= new Date() ||
            isRestricted?.restricted
              ? "videoDetailImg videoDisable"
              : newLaunchVideo
              ? "videoDetailImg activeVideoImg"
              : "videoDetailImg"
          }
        >
          {newLaunchVideo ? (
            <React.Fragment>
              <div style={{ height: "100%", width: "100%" }}>
                <div className="videoControls">
                  <button
                    className={!isAudioGlobal && "active"}
                    onClick={() => dispatch(setAudioGlobal(!isAudioGlobal))}
                  >
                    <img alt={"$$$"} src={MicroPhoneIcon} />
                  </button>

                  {booking?.booking_access_type === "video" ? (
                    <button
                      className={!isVideoGlobal && "active"}
                      onClick={() => dispatch(setVideoGlobal(!isVideoGlobal))}
                    >
                      <img src={VideoCallIcon} alt="$$$" />
                    </button>
                  ) : null}

                  <button className="callcut" onClick={() => callCut()}>
                    <img alt={"$$$"} width="8px" src={CallCut} />
                  </button>
                  <button
                    className={fullScreen && "active"}
                    onClick={() => dispatch(setFullScreen(!fullScreen))}
                  >
                    <img alt={"$$$"} src={MaxIcon} />
                  </button>
                </div>

                {room && (
                  <div
                    className={
                      fullScreen
                        ? "personal-screen full-personal-screen"
                        : "personal-screen"
                    }
                  >
                    <ConsultationParticipant
                      localUser={true}
                      isAudio={isVideoGlobal}
                      isVideo={isVideoGlobal}
                      key={room.localParticipant.sid}
                      participant={room.localParticipant}
                      videoRef={videoRefLocal}
                      audioRef={audioRefLocal}
                      booking={booking}
                    />
                  </div>
                )}
                {remoteParticipants ? (
                  <div className="remote-participants">
                    {remoteParticipants}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: "13px",
                    }}
                    className="remote-participants"
                  >
                    Participant have turned off their Cam
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {booking?.booking_access_type === "audio" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    position: "absolute",
                  }}
                >
                  <p>Please launch call to continue</p>
                </div>
              ) : allowCamera ? (
                <React.Fragment>
                  {isCameraOn && <Webcam videoConstraints={videoConstraints} />}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                      position: "absolute",
                    }}
                    onClick={() => allowStaticCamera()}
                  >
                    <img alt={"$$$"} src={CameraIcon} />
                    <p>Disallow camera</p>
                  </div>
                </React.Fragment>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => allowStaticCamera()}
                >
                  <img alt={"$$$"} src={CameraIcon} />
                  <p>Allow camera</p>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        {!newLaunchVideo && (
          <div
            onClick={() => launchVideoCall()}
            className={
              new Date(booking?.schedule?.end_time) <= new Date() ||
              isRestricted?.restricted
                ? "videoFooter disable"
                : "videoFooter"
            }
          >
            {booking?.urgent_care ? (
              isRestricted?.restricted ? (
                <p style={{ color: "#fff" }}>{isRestricted?.reason}</p>
              ) : (
                <h2>Launch Call</h2>
              )
            ) : (
              <React.Fragment>
                {new Date() <=
                  new Date(booking?.schedule?.start_time).setMinutes(
                    new Date(booking?.schedule?.start_time).getMinutes() - 5
                  ) &&
                  !isRestricted?.restricted && (
                    <h2
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontWeight: "500",
                      }}
                    >
                      <img
                        alt={"$$$"}
                        style={{ width: "20px" }}
                        src={TimerIcon}
                      />{" "}
                      {timeRemaining}
                    </h2>
                  )}

                {new Date() >=
                  new Date(booking?.schedule?.start_time).setMinutes(
                    new Date(booking?.schedule?.start_time).getMinutes() - 5
                  ) &&
                  new Date() <= new Date(booking?.schedule?.end_time) &&
                  !isRestricted?.restricted && (
                    <>
                      <h2>Launch Call</h2>
                    </>
                  )}
                {(new Date(booking?.schedule?.end_time) <= new Date() ||
                  isRestricted?.restricted) && (
                  <>
                    {isRestricted?.restricted ? (
                      <p style={{ color: "#fff" }}> {isRestricted?.reason}</p>
                    ) : (
                      <h2>Time Out</h2>
                    )}
                  </>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>

      {/* <Modal
        width="60%"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          window.location.pathname = "/consultations";
        }}
        footer={false}
        closable={false}
        centered
      >
        <Review bookingId={booking?._id} />
      </Modal> */}
    </>
  );
};

export default ConsultationVideoCall;
