import React, { useEffect, useRef, useState } from "react";
import Header from "../../Layouts/Header/Header";
import { Badge, Col, message, Row, Spin } from "antd";
import {
  getRequest,
  postRequestFormData,
  putRequest,
} from "../../../service/ApisFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Messages from "../../MessagesData/Messages/Messages";
import "./urgentMessage.scss";
import { ArrowLeft, DomyD, VideoCallIcon } from "../../../assets";
import OtherDetails from "../EHR/ConsDetails/otherDetails";
import BlueButton from "../../UI/BlueButton/BlueButton";
import { ReactSVG } from "react-svg";
import { socket } from "../../../Utils/baseUrl";

const UrgnetChatBox = () => {
  const bookingIdParam = useParams();
  const notificationCount = useSelector(
    (state) => state?.generalReducer?.notificationCount
  );
  const navigate = useNavigate();
  const [singleBooking, setSingleBooking] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [doctorDetial, setDoctorDetial] = useState({});
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [fileToSend, setFileToSend] = useState(null);
  const [singleMessage, setMessage] = useState("");
  const [mobileSelectedDoctor, setMobileSelectedDoctor] = useState({});
  const [hasFetchedDoctorDetails, setHasFetchedDoctorDetails] = useState(false);
  const [showRingingAgainButton, setShowRingingAgainButton] = useState(false);
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds

  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const socketRef = useRef(socket); // Store socket instance in ref
  const inputRef = useRef(null); // Ref for the input field

  const getbookingById = async () => {
    setChatLoading(true);

    const onSuccess = (res) => {
      setSingleBooking(res?.data);
      setChatLoading(false);
    };

    const onError = (err) => {
      setChatLoading(false);
    };

    const route = `booking/booking_detail/${bookingIdParam?.id}`;
    await getRequest("", route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (bookingIdParam?.id || notificationCount) {
      getbookingById();
    }
  }, [bookingIdParam, notificationCount]);

  const getDoctorDtails = async (id) => {
    setChatLoading(true);

    const onSuccess = (res) => {
      setDoctorDetial(res?.data);
      setMessages(res?.data?.messages);
      setFilteredMessages(res?.data?.messages);
      setChatLoading(false);
    };

    const onError = () => {
      setChatLoading(false);
    };

    const route = `chat/chat_detail/${id}`;

    await getRequest("", route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (singleBooking?.chat_id && !hasFetchedDoctorDetails) {
      getDoctorDtails(singleBooking.chat_id);
      setHasFetchedDoctorDetails(true);
    }
  }, [singleBooking, hasFetchedDoctorDetails]);

  useEffect(() => {
    if (bookingIdParam?.id || notificationCount) {
      setHasFetchedDoctorDetails(false); // Reset when bookingIdParam or notificationCount changes
    }
  }, [bookingIdParam, notificationCount]);

  
  const sendMessage = () => {
    if (!fileToSend) {
      if (singleMessage.trim() !== "") {
        const messageData = {
          senderId: completeUser?.user_id?._id,
          text: singleMessage,
          conversationId: doctorDetial?._id,
        };
        socketRef.current.emit("chat", messageData, (acknowledgment) => {
          if (acknowledgment === "sent") {
            setMessage("");
            inputRef.current.focus(); // Focus the input field after sending the message
          }
        });
      }
    } else {
      sendFile();
      setFileToSend(null);
      setMessage("");
      inputRef.current.focus(); // Focus the input field after sending the message
    }
  };

  useEffect(() => {
    if (doctorDetial._id) {
      socketRef.current.emit("join", {
        conversationId: doctorDetial._id,
        userId: completeUser?.user_id?._id,
      });

      const handleChat = (chat) => {
        if (chat?.conversationId) {
          const newMessage = {
            date: chat?.date,
            sender: chat?.senderId,
            _id: chat?.conversationId,
            status: "sent",
          };

          if (chat?.file) {
            newMessage.file = chat?.file;
          } else {
            newMessage.message = chat?.text;
          }

          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setFilteredMessages((prevMessages) => [...prevMessages, newMessage]);

          if (chat?.senderId !== completeUser?.user_id?._id) {
            socketRef.current.emit("messageSeen", {
              chatId: chat?.conversationId,
              messageId: chat?.messageId,
              text: chat?.text,
              conversationId: chat?.conversationId,
            });
          }
        }
      };

      socketRef.current.on("chat", handleChat);

      return () => {
        socketRef.current.off("chat", handleChat); // Clean up on unmount
      };
    }
  }, [doctorDetial?._id, completeUser?.user_id?._id]);

  useEffect(() => {
    socketRef.current.on("setMessageSeen", (payload) => {
      const updatedMessages = messages.map((message) => {
        if (message._id === payload?.conversationId) {
          return { ...message, status: payload?.status };
        }
        return message;
      });
      setMessages(updatedMessages);
      setFilteredMessages(updatedMessages);
    });

    return () => {
      socketRef.current.off("setMessageSeen"); // Clean up on unmount
    };
  }, [messages]);

  const onMessageSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const filtered = messages.filter((message) => {
        return message?.message?.toLowerCase().replace(/\s/g, '').includes(value.toLowerCase().replace(/\s/g, ''));
      });
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages(messages);
    }
  };

  const sendFile = () => {
    socket.emit(
      "upload",
      fileToSend,
      completeUser?.user_id?._id,
      doctorDetial?._id
    );
  };

  const beforeUpload = async (file) => {
    const isPdfOrImage =
    (file.type.startsWith("image/") && file.type !== "image/svg+xml") || file.type === "application/pdf";

    if (!isPdfOrImage) {
      message.warning("You can only upload PDF and image files!");
      return false;
    }

    setImageLoading(true);

    const onSuccess = (res) => {
      setImageLoading(false);

      setFileToSend(res?.data);
    };

    const onError = () => {
      setImageLoading(false);
    };

    const formData = {
      image: file,
    };

    await postRequestFormData(
      formData,
      "auth/upload_image_s3",
      true,
      onSuccess,
      onError
    );

    // setFileToSend(file);

    return false;
  };

  const props = {
    beforeUpload,
    fileList: null,
  };

  const ringingAgain = async (bookingId) => {
    setChatLoading(true);

    const onSuccess = (res) => {
      message.success(res?.message);
      setChatLoading(false);
      getbookingById();
    };
    const onError = (err) => {
      message?.error(err?.message);
      setChatLoading(false);
    };

    const formData = {
      ringing_again: true,
    };

    await putRequest(
      formData,
      `booking/ringing_Again/${bookingId}`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (singleBooking) {
      const createdAtDate = new Date(singleBooking.createdAt);
      const currentDate = new Date();
      const diffInMillis = currentDate - createdAtDate;
      const twoMinutesInMillis = 2 * 60 * 1000;

      if (diffInMillis >= twoMinutesInMillis) {
        setShowRingingAgainButton(true);
      } else {
        const remainingTime = twoMinutesInMillis - diffInMillis;
        setCountdown(Math.ceil(remainingTime / 1000));

        const interval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(interval);
              setShowRingingAgainButton(true);
              return 0;
            }
            return prevCountdown - 1;
          });
        }, 1000);

        return () => clearInterval(interval);
      }
    }
  }, [singleBooking]);


  return (
    <>
      <Header />

      <Row>
        <Col span={16}>
          <div className="messageArea">
            <Messages
              props={props}
              completeUser={completeUser}
              setMessage={setMessage}
              message={singleMessage}
              filteredMessages={filteredMessages}
              sendMessage={sendMessage}
              patientDetial={doctorDetial}
              onMessageSearch={onMessageSearch}
              fileToSend={fileToSend}
              setFileToSend={setFileToSend}
              setMobileSelectedDoctor={setMobileSelectedDoctor}
              chatLoading={chatLoading}
              imageLoading={imageLoading}
              getbookingById={getbookingById}
              inputRef={inputRef}
              ringLimit={
                singleBooking?.chat_id ? false : singleBooking?.ringing_again
              }
              style={{
                width: "100%",
                height: singleBooking?.chat_id ? "auto" : "82vh",
              }}
              requestPending={
                singleBooking?.chat_id
                  ? null
                  : "We are in the process of connecting you with an available  provider, Please stay online as one of our providers will join you shortly."
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="rightSideMessageArea">
            <div className="rightSideMessageHeader">
              <h1>About</h1>
              <p
                onClick={() =>
                  navigate(`/consultation-detail/${singleBooking?._id}`)
                }
              >
                Consultation Details
              </p>
            </div>
            <div className="profileImage">
                
              <div className="image">
                  <img
                    src={singleBooking?.provider_id?.profile_image || DomyD}
                    alt="$$$"
                  />

                  <div style={{background:singleBooking?.provider_id?.is_online ? "" :"lightGray"}} className="onlineDot" ></div>
              </div>
              
              <h3>
                {singleBooking?.provider_id?.first_name}{" "}
                {singleBooking?.provider_id?.middle_name || ""}{" "}
                {singleBooking?.provider_id?.last_name}
              </h3>
              <p>{singleBooking?.provider_id?.user_id?.email || ""}</p>
              <h6>{singleBooking?.provider_id?.is_online ? "Active" : "InActive"}</h6>
            </div>
            <div className="bookingDetails">
              <h3>Consultation for</h3>
              <p>
                {singleBooking?.booking_type === "video"
                  ? singleBooking?.category?.category_name ||
                    singleBooking?.speciality?.speciality_name
                  : singleBooking?.program?.treatment_name}
              </p>
            </div>
            <div className="bookingDetails">
              <h3>Payment</h3>
              <p>${singleBooking?.discountedPrice || singleBooking?.payment}</p>
            </div>

            {singleBooking?.booking_access_type === "video" && (
              <div
                onClick={() =>
                  navigate(`/consultation-detail/${singleBooking?._id}`)
                }
                className="DetailsMessage"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <div className="DetailsMessageLeft">
                  <h2>Join video call</h2>
                  {/* <ReactSVG style={{ marginTop: "7px" }} src={ArrowLeft} /> */}
                </div>
                <div
                  style={{
                    background: "#f76d00",
                    padding: "8px",
                    borderRadius: "100px",
                  }}
                >
                  <ReactSVG src={VideoCallIcon} alt="$$$" />
                </div>{" "}
              </div>
            )}

            <div style={{ marginTop: "20px" }}>
              <OtherDetails
                title="Medical history"
                questions={singleBooking?.medicalHistory}
                check={false}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <OtherDetails
                title="Questionnaire"
                questions={singleBooking?.pre_questions}
                check={false}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <OtherDetails
                title="Social history"
                questions={singleBooking?.socialHistory}
                check={false}
              />
            </div>
            {singleBooking?.chat_id ? null :  singleBooking?.ringing_again   ? null : (
              <Spin spinning={chatLoading}>
                <BlueButton
                  onClick={() => ringingAgain(singleBooking?._id)}
                  style={{ width: "100%", marginTop: "20PX" }}
                  disabled={!showRingingAgainButton}
                >
                  {showRingingAgainButton
                    ? "Ring again"
                    : `Ring again in ${countdown}s`}
                </BlueButton>
              </Spin>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UrgnetChatBox;
