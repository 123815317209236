import React, { useState } from "react";
import "./mobileMenu.scss";
import SkeletonInput from "../../../UI/Skeleton/skeletonInput";
import { Col, Divider, Row } from "antd";
import { down } from "../../../../assets";
import ServiceMenu from "../ServiceMenu";
import ProgramMenu from "../ProgramMenu";
import SpecialityMenu from "../SpecialityMenu";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSpeacilityDetails,
  setSpeacilityImage,
  setbookingType,
} from "../../../../Redux/Reducers/authSlice";
import {
  setBookableFlowCondition,
  setBookableFlowProgram,
  setBookableFlowSpeciality,
  setBookingFlowPrice,
  setBookingFlowType,
} from "../../../../Redux/Reducers/bookingSlice";

const MobileMenu = ({ setOpenDrawer, headerNavigation }) => {
  const [activeService, setActiveService] = useState(null);
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);

  const token = useSelector((state) => state.authReducer.paitentToken);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleServiceMenu = (item) => {
    setActiveService(item);
    if (item?._id === activeService?._id) {
      setActiveService(null);
      return;
    }
  };

  const handleSpeciltyClick = (item) => {
    dispatch(setBookableFlowProgram(null));

    dispatch(setBookableFlowSpeciality(item?.speciality));
    dispatch(setBookableFlowCondition(null));
    dispatch(setBookingFlowType(item?.service?.flow));

    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          (item?.specialilty?.price > 0 && item?.specialilty?.price) ||
          item?.specialilty?.categories?.[0]?.price
      )
    );
    navigate(`/speciality-booking-details/${item?.speciality?._id}`);
  };

  const getConditionById = (item) => {
    dispatch(setbookingType(item?.service?.flow));
    dispatch(setBookableFlowCondition(item?.specialilty?.categories?.[0]));
    dispatch(setBookableFlowSpeciality(item?.specialilty));

    dispatch(setSpeacilityDetails(item?.specialilty?.categories?.[0]));
    dispatch(setBookingFlowType(item?.service?.flow));
    dispatch(setSpeacilityImage(item?.specialilty?.image));
    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          (item?.specialilty?.price > 0 && item?.specialilty?.price) ||
          item?.specialilty?.categories?.[0]?.price
      )
    );
    setOpenDrawer(false);
    navigate("/booking-details");
  };

  const getProgramById = (item) => {
    dispatch(setSpeacilityDetails(item?.program));
    dispatch(setSpeacilityImage(item?.program?.image));
    dispatch(setbookingType(item?.service?.flow));
    dispatch(setBookableFlowProgram(item?.program));
    dispatch(setBookingFlowType(item?.service?.flow));
    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          item?.program?.price
      )
    );
    setOpenDrawer(false);
    navigate(`/treatment-consultations-details`);
  };

  return (
    <div className="mobileMenu">
      <Row gutter={[20, 20]}>
        {!headerNavigation
          ? [1, 2, 3, 4, 5]?.map((item, index) => (
              <Col key={index} span={24}>
                <SkeletonInput block={true} />
              </Col>
            ))
          : headerNavigation?.map((item, index) => (
              <Col key={index} span={24}>
                <div
                  onClick={() => toggleServiceMenu(item)}
                  className="menuService"
                >
                  {item?.type === "Medical consultation" ||
                  item?.type === "Treatment programs" ||
                  // item?.type === "specility" ? (
                  //   <>
                  //     <p>{item?.name}</p>
                  //     <img
                  //       style={{
                  //         transition: "ease-in .3s",
                  //         transform:
                  //           item?._id === activeService?._id
                  //             ? "rotate(180deg)"
                  //             : "rotate(0deg)",
                  //       }}
                  //       src={down}
                  //       alt="$$$"
                  //     />
                  //   </>
                  // ) :
                   item?.type === "condition" ? (
                    <p onClick={() => getConditionById(item)}>{item?.name}</p>
                  ) : item?.type === "program" ? (
                    <p onClick={() => getProgramById(item)}>{item?.name}</p>
                  ) : item?.type === "specility" ? <p onClick={() => handleSpeciltyClick(item)}>{item?.name}</p> :null }
                </div>
                {/* {item?._id === activeService?._id ? (
                  item?.type === "Medical consultation" ? (
                    <ServiceMenu
                      setOpenDrawer={setOpenDrawer}
                      service={item}
                      setOpenPopoverIndex={setOpenPopoverIndex}
                    />
                  ) : item?.type === "Treatment Programs" ? (
                    <ProgramMenu
                      setOpenDrawer={setOpenDrawer}
                      service={item}
                      setOpenPopoverIndex={setOpenPopoverIndex}
                    />
                  ) : item?.type === "specility" ? (
                    <SpecialityMenu
                      setOpenDrawer={setOpenDrawer}
                      service={item}
                      setOpenPopoverIndex={setOpenPopoverIndex}
                    />
                  ) : null
                ) : null} */}
                <Divider />
              </Col>
            ))}
        {/* <Col span={24}>
          <Link
          >
            <p>Lab Testing</p>
          </Link>

          <Divider />
        </Col> */}
        <Col span={24}>
          <Link to="/contact">
            <p>Contact us</p>{" "}
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default MobileMenu;
