import { Checkbox, Col, Form, Input, Modal, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import BlueButton from "../../../../Components/UI/BlueButton/BlueButton";
import { getRequest } from "../../../../service/ApisFunctions";
import BackButton from "../../../../Components/UI/BackButton/BackButton";
import { consentForms } from "../../../../Utils/constant";
import SignUpConsentForm from "../../../../Components/ConsentForm/signUpConsentForm";
import { useSelector } from "react-redux";
import { ValidateMaxLength } from "../../../../Utils/helper";

const ThirdStep = ({
  setSignUpSteps,
  setSignUpData,
  handleSubmitFinal,
  signUpData,
}) => {

  const consentForms = useSelector(
    (state) => state.generalReducer.consentForms
  );

  const [allStates, setAllStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileLocation, setProfileLocation] = useState({
    address1: "",
    address2: "",
    coordinates: [0, 0],
  });
  // const [consentForm, setConsentForm] = useState({
  //   telemedicineServices: { name: "", check: false },
  //   discloseHealth: { name: "", check: false },
  //   patientLocation: { name: "", check: false },
  //   healthDataAnalyticsConsent: { name: "", check: false },
  // });
  const [consentCheck, setConsentCheck] = useState(false);

  const [form] = Form.useForm();

  const getAllSatates = async () => {
    setLoading(true);
    const onSuccess = (res) => {
      setAllStates(res?.data);
      setLoading(false);
    };

    const onError = (err) => {
      setLoading(false);
      message.error(err?.message);
    };

    await getRequest(
      "",
      "state/active_state_listing",
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getAllSatates();
  }, []);

  const handleSubmit = async (e) => {
    if (
      // consentForm?.telemedicineServices?.check &&
      // consentForm?.discloseHealth?.check &&
      // consentForm?.patientLocation?.check &&
      // consentForm?.healthDataAnalyticsConsent?.
      consentCheck
    ) {
      const createPatient = {
        ZipCode: e?.ZipCode,
        City: e?.City,
        state: e?.State,
        location: profileLocation,
        allowConsent: consentCheck,
        consentHistory : consentForms
        // isTelemedicineConsentAllowed: consentForm?.telemedicineServices?.check,
        // isDiscloseHealthConsentAllowed: consentForm?.discloseHealth?.check,
        // isPatientLocationConsentAllowed: consentForm?.patientLocation?.check,
        // isHealthDataAnalyticsConsentAllowed:
        //   consentForm?.healthDataAnalyticsConsent?.check,
      };
      const updatedSignUpData = { ...signUpData, ...createPatient };
      setSignUpData(updatedSignUpData);
      handleSubmitFinal(updatedSignUpData);
    } else {
      message.warning("Please agree to the terms and conditions");
    }
  };

  const handleInputsChange = (key, value) => {
    if (key === "address1") {
      setSignUpData({
        ...signUpData,
        location: {
          ...signUpData.location,

          address1: value,
          coordinates: [0, 0],
        },
      });
      setProfileLocation((prevLocation) => ({
        ...prevLocation,
        address1: value,
        coordinates: [0, 0],
      }));
    } else if (key === "address2") {
      setSignUpData({
        ...signUpData,
        location: {
          ...signUpData.location,
          address2: value,
          coordinates: [0, 0],
        },
      });
      setProfileLocation((prevLocation) => ({
        ...prevLocation,
        address2: value,
        coordinates: [0, 0],
      }));
    } else {
      setSignUpData({ ...signUpData, [key]: value });
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      ZipCode: signUpData?.ZipCode || null,
      City: signUpData?.City || null,
      State: signUpData?.state || null,
      address1: signUpData?.location?.address1 || null,
    });
    setProfileLocation(signUpData?.location || null);
  }, []);

  // const handleConsentForm = (type) => {
  //   setIsModalOpen(true);

  //   const updatedForm = {
  //     telemedicineServices: {
  //       name: type === "telemedicineServices" ? "telemedicineServices" : "",
  //       check: consentForm.telemedicineServices?.check,
  //     },
  //     discloseHealth: {
  //       name: type === "discloseHealth" ? "discloseHealth" : "",
  //       check: consentForm.discloseHealth?.check,
  //     },
  //     patientLocation: {
  //       name: type === "patientLocation" ? "patientLocation" : "",
  //       check: consentForm.patientLocation?.check,
  //     },

  //     healthDataAnalyticsConsent: {
  //       name:
  //         type === "healthDataAnalyticsConsent"
  //           ? "healthDataAnalyticsConsent"
  //           : "",
  //       check: consentForm.healthDataAnalyticsConsent?.check,
  //     },
  //   };

  //   setConsentForm({
  //     ...consentForm,
  //     ...updatedForm,
  //   });
  // };

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="address1"
              label="Street"
              rules={[
                {
                  required: true,
                  message: "Please enter Street",
                },
                ValidateMaxLength(35, "Street")
              ]}
            >
              <Input
                className="ant-input-affix-wrapper"
                placeholder="Enter Street"
                value={signUpData?.location?.address1}
                onChange={(e) =>
                  handleInputsChange("address1", e?.target?.value)
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="City"
              label="City"
              rules={[
                { required: true, message: "Please enter city" },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "City must contain only alphabets",
                },
                ValidateMaxLength(35, "City")

              ]}
            >
              <Input
                onChange={(e) => handleInputsChange("City", e?.target?.value)}
                autoComplete="off"
                value={signUpData?.City}
                className="ant-input-affix-wrapper"
                placeholder="Please enter city"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="State"
              label="State"
              rules={[
                {
                  required: true,
                  message: "Please select state",
                },
                {
                  max: 2,
                  message: "state should not exceed 2 characters",
                },
                ValidateMaxLength(10, "State")
              ]}
            >
              <Select
                maxLength={2}
                className="ant-select-selector"
                placeholder="State"
                value={signUpData?.state}
                onChange={(e) => handleInputsChange("state", e)}
              >
                {allStates?.map((item, index) => (
                  <Select.Option key={index} value={item?.state}>
                    {item?.state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="ZipCode"
              label="Zip Code"
              rules={[
                { required: true, message: "Please enter only digits" },
                {
                  pattern: /^[0-9]{5}$/,
                  message: "Zip Code must be exactly 5 digits",
                },
              ]}
            >
              <Input
                value={signUpData?.ZipCode}
                onChange={(e) =>
                  handleInputsChange("ZipCode", e?.target?.value)
                }
                type="number"
                maxLength={5}
                autoComplete="off"
                className="ant-input-affix-wrapper"
                placeholder="Please enter zip code"
              />
            </Form.Item>
          </Col>

          <Col className="signUpConsent" span={24}>
            <Form.Item name="consent" rules={[
                { required: consentCheck ? false : true, message: "Please agree to the terms and conditions" },
                
              ]} >
              <Checkbox
              checked={consentCheck}
              onChange={(e) => setConsentCheck(e.target.checked)}
              >
                I have read and agree to
                <a href="/terms-of-use" target="_blank">
                  <span className="title">Terms of Use</span>
                </a>
                ,
                <a href="/responsibility-and-billing" target="_blank">
                  <span className="title">
                    Financial Responsibility and Billing
                  </span>
                </a>{" "}
                and the
                <a href="/privacy-policy" target="_blank"><span className="title">Privacy policy</span></a>.
              </Checkbox>
            </Form.Item>
            {/* <Checkbox
              checked={consentForm.discloseHealth.check}
              onChange={(e) => handleConsentForm("discloseHealth")}
            >
              I have read and agree to the Consent to Use and
              <span className="title"> Disclose Health Information</span>.
            </Checkbox>
            <Checkbox
              checked={consentForm.patientLocation.check}
              onChange={(e) => handleConsentForm("patientLocation")}
            >
              I have read and agree to the Consent for
              <span className="title"> Patient Location</span>.
            </Checkbox>

            <Checkbox
              checked={consentForm.healthDataAnalyticsConsent.check}
              onChange={(e) => handleConsentForm("healthDataAnalyticsConsent")}
            >
              I have read and agree to the Consent for
              <span className="title"> Health Data Analytics</span>.
            </Checkbox> */}
          </Col>

          <Col style={{ marginTop: "20px" }} span={6}>
            <BackButton
              onClick={() => setSignUpSteps(1)}
              type="button"
              width="100%"
            >
              Back
            </BackButton>
          </Col>
          <Col style={{ marginTop: "20px" }} span={18}>
            <BlueButton type="submit" width="100%">
              Sign Up
            </BlueButton>
          </Col>
        </Row>
      </Form>

      {/* <Modal
        width="80%"
        open={isModalOpen}
        // onCancel={() => {
        //   setIsModalOpen(false);
        // }}
        footer={false}
        closable={false}
        centered
      >
        <SignUpConsentForm
          setConsentForm={setConsentForm}
          consentForm={consentForm}
          setIsModalOpen={setIsModalOpen}
          consent={
            consentForms[
              consentForm.telemedicineServices.name ||
                consentForm.discloseHealth.name ||
                consentForm.patientLocation.name ||
                consentForm.healthDataAnalyticsConsent.name
            ]
          }
        />
      </Modal> */}
    </>
  );
};

export default ThirdStep;
